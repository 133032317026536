html {
  background-image: url("./static/background.jpg");
  background-size: cover;
  background-attachment: fixed;
  /* width: 100%; */
  /* height: 100%; */
  width: 100%;
  text-align: center;
  line-height: 24px;

  @media (min-width: 1800px) {
    background-image: url("./static/background_md.jpg");
  }
}

.header {
  background: #fff;
  /* position: fixed; */
  /* left: 0; */
  /* right: 0; */
  /* top: 0; */
  padding-top: 1.6em;
  /* border-radius: 0 0 10px 10px; */
  box-shadow: 0px 0px 26px 40px #fff;
  /* height: 1em; */
}

.logo {
  width: 20em;
  box-shadow: 0px 0px 26px 40px #fff;
}

a:visited {
  color: rgb(0, 77, 0);
}

a {
  color: rgb(0, 77, 0);
  text-decoration: none;
  font-size: 1.1em;
}

.app {
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(16px);
  box-shadow: 0px 0px 10px -1px #fff;
  text-align: center;
  border-radius: 20px;
  margin: 4em auto 4em auto;
  display: inline-block;
  overflow: hidden;
  max-width: 96%;

  @media (min-width: 768px) {
    max-width: 70%;
    margin: 6em auto 4em auto;
  }

  /* box-shadow: 1px 1px 1px red; */
}

.content {
  text-align: left;
  padding: 1em 2em;
  font-size: 1.1em;

  .title {
    text-align: center;
    padding-bottom: 0.8em;
  }
}

.email {
  margin-top: 2em;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  padding: 0.3em 1em;
  list-style: none;
}

.footer {
  margin-top: 6em;
  /* position: fixed; */
  /* bottom: 0; */
  /* text-align: center; */
  /* left: 0; */
  /* right: 0; */
  padding-bottom: 1em;
}
